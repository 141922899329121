import React from "react"
import Slider from "react-slick";
import Layout from "../components/layout"
import { SlideTextSmall, SlideH3, ServiceContainer, MainSection, MainContainer, USPContainer, USPHeading, USPText, IconContainer, ServiceTextContainer, ServiceSection, ServiceHeading, ReferenceSection, ReferenceHeader, ReferenceOverlay, USP, SliderContainer, ServiceMainContainer } from "../styles/common.styled"
import { Slide, SlideText, SlideH1, SlideP, SlideButton } from "../styles/header.styled";
import '../css/icons.css'
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'
import CTA from "../components/cta";
import Testimonials from "../components/testimonlials";
import SEO from "../components/seo";

export const query = graphql`
  {
    page: sanityFrontPage {
      title
      slider {
        _key
        buttonLink
        buttonText
        heading
        text
        image {
          asset {
            url
          }
        }
      }
      teasers {
        _key
        heading
        text
        url
        image {
          asset {
            fluid(maxWidth: 356) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      services {
        _key
        icon
        heading
        text
      }
      seo {
        description,
        image {
          asset {
            url
          }
        }
      }
    }
    references: allSanityWorkReference {
      nodes {
        _id
        heading
        text
        image {
          asset {
            url
          }
        }
        slug {
          current
        }
      }
    }
    cta: sanityCta(slug: {current: {eq: "oppussing"}}) {
      buttonLink
      buttonText
      heading
    }
    testimonials: allSanityTestimonials {
      nodes {
        _id
        name
        text
      }
    }
  }
`


const IndexPage = ({data}) =>  {
  let mainSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: 'linear',  
    arrows: false   
  };

  let referenceSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: 'linear',     
  };



  return (
    <Layout>
      <SEO seo={data.page.seo} title={data.page.title} />

      
      <Slider {...mainSettings}>
        {data.page.slider.map(({buttonLink, buttonText, heading, image, text, _key}) => (
        <Slide key={_key} image={`${image.asset.url}?auto=format`}>
          <SlideText>
            <SlideH1>{heading}</SlideH1>
            <SlideP>{text}</SlideP>
            <SlideButton to={buttonLink}>{buttonText}</SlideButton>
          </SlideText>
          </Slide>      
        ))}
      </Slider>

      
      <MainSection>
        <MainContainer className="w-container">
          <USPContainer>
          {data.page.teasers.map(({heading, text, url, image, _key}) => (
            <USP key={_key}>
              <Link to={url}><Img fluid={image.asset.fluid} alt=""/></Link>
              <USPHeading>{heading}</USPHeading>
              <USPText>{text}<br/></USPText>
              <Link to={url}>Les mer</Link>
            </USP>

          ))}
          </USPContainer>
        </MainContainer>
      </MainSection>

      <ServiceSection>
      <ServiceMainContainer className="w-container">

            {data.page.services.map(({icon, heading, text, _key}) => (
            <ServiceContainer key={_key}>
              <div>
              <IconContainer>
                <div className={`icon-${icon}`}></div>
              </IconContainer>
              </div>
              
              <ServiceTextContainer>
                <ServiceHeading>{heading}</ServiceHeading>
                <p>{text}</p>
              </ServiceTextContainer>
              </ServiceContainer>
            ))}
        </ServiceMainContainer>
          </ServiceSection>            

      <ReferenceSection>
            <MainContainer className="w-container">
              <ReferenceHeader>Utvalgte prosjekter</ReferenceHeader>
              <Slider {...referenceSettings}>

                {data.references.nodes.map(({heading, text, image, _id}) => (
                    <Link key={_id} to={`/referanser`}>

                      <SliderContainer>
                      <Slide image={`${image.asset.url}?auto=format`}>
                        <ReferenceOverlay>

                          <SlideH3>{heading}</SlideH3>
                          <SlideTextSmall>{text}</SlideTextSmall>               

                        </ReferenceOverlay>
                      </Slide>      
                      </SliderContainer>
                    </Link>
                    

                ))}
              </Slider>


            </MainContainer>
          </ReferenceSection>
          
          <CTA {...data.cta} />
          <Testimonials testimonials={data.testimonials.nodes} />


    </Layout>

  )
}


export default IndexPage
