import React from 'react'
import { CTASection, MainContainer, CTAButton } from '../styles/common.styled'

const CTA = ({heading, buttonLink, buttonText}) => (
  <CTASection>
    <MainContainer>
      <h3>{heading}</h3>
      <CTAButton to={buttonLink}>{buttonText}</CTAButton>
    </MainContainer>
  </CTASection>

)

export default CTA