import React from 'react'
import { TestimonialSection, MainContainer, TextSlide, TestimonialText, TestimonialName } from '../styles/common.styled'
import Slider from 'react-slick'



const Testimonials = ({testimonials}) => {

    let testimonialSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false
      };  
        

    return (
        <TestimonialSection>
        <MainContainer>
        <h2>Noen fornøyde kunder</h2>
        <Slider {...testimonialSettings}>
            {testimonials.map(({text, name, _id}) => (
            <TextSlide key={_id}>
                <TestimonialText>
                    {text}
                </TestimonialText>
                <TestimonialName>
                    {name}
                </TestimonialName>
                </TextSlide>
            ))}
        </Slider>
        </MainContainer>
        

    </TestimonialSection>
    )
}

export default Testimonials